<template>
  <div class="container">
    <div class="form-view">
      <div class="form">
        <div class="item">
          <div class="label">{{ $t("common.balance") }}：{{ balance }}</div>
        </div>
        <div class="item">
          <div class="label">{{ $t("withdrawal.withdrawalQuantity") }}</div>
          <van-field
            v-model="number"
            type="number"
            input-align="center"
            :formatter="formatter"
            :placeholder="$t('warning.withdrawalQuantity')"
          />
        </div>
      </div>
      <button class="max-button" type="button" @click="submit">
        {{ $t("common.submit") }}
      </button>
      <!-- loading start -->
      <loading :loading="is_loading" text="Loading..." />
      <!-- loading end -->
    </div>
    <div class="mescroll-view">
      <mescroll-vue
        class="mescroll"
        id="container"
        ref="mescroll"
        :down="mescrollDown"
        :up="mescrollUp"
        @init="mescrollInit"
      >
        <div class="mescroll-view">
          <div
            class="mescroll-item"
            v-for="(item, index) in dataList"
            :key="index"
          >
            <div class="text">
              <div class="title">{{ item.content }}</div>
              <div class="number">{{ item.money }}</div>
            </div>
            <div class="text2">
              <div class="date">{{ item.create_time }}</div>
              <div class="label">{{ $t("common.number") }}</div>
            </div>
          </div>
        </div>
      </mescroll-vue>
    </div>
  </div>
</template>

<script>
import MescrollVue from "mescroll.js/mescroll.vue";
import { mapState } from "vuex";
import { getFinanceList, withdrawal, getUserInfo } from "@/request/api";
import {
  getChainId,
  sendTransaction,
  getBalance,
  getGasLimit2,
  usdcTokenAddress,
  simucollectionAddress,
  mccTokenAddress
} from "@/common/metaMask/index";
import loading from "@/components/loading";
export default {
  components: { MescrollVue, loading },
  computed: {
    ...mapState({
      walletAddress: (state) => state.user.walletAddress,
    }),
  },
  data() {
    return {
      address: "",
      number: "",

      mescroll: null,
      mescrollDown: {
        textInOffset: this.$t("mescroll.textInOffset"),
        textOutOffset: this.$t("mescroll.textOutOffset"),
        textLoading: this.$t("mescroll.textLoading"),
      },
      mescrollUp: {
        callback: this.upCallback,
        empty: {
          tip: this.$t("mescroll.empty"),
          warpId: "container",
          icon: "/images/mescroll-empty.png",
        },
        htmlLoading: `<p class="upwarp-progress mescroll-rotate"></p><p class="upwarp-tip">${this.$t(
          "mescroll.loading"
        )}</p>`,
        htmlNodata: `<p class="upwarp-nodata">-- ${this.$t(
          "mescroll.end"
        )} --</p>`,
      },
      dataList: [], // 列表数据
      is_loading: false,
      balance: "",
    };
  },
  mounted() {
    this.get_getUserInfo();
  },
  methods: {
    get_getUserInfo() {
      getUserInfo().then((res) => {
        if (res.code == 200) {
          this.balance = res.data.balance;
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
    mescrollInit(mescroll) {
      this.mescroll = mescroll;
    },
    upCallback(page, mescroll) {
      getFinanceList({ page: page.num, limit: page.size, type: 6 }).then(
        (res) => {
          if (res.code == 200) {
            // 请求的列表数据
            let arr = res.data.list;
            // 如果是第一页需手动置空列表
            if (page.num === 1) this.dataList = [];
            // 把请求到的数据添加到列表
            this.dataList = this.dataList.concat(arr);
            // 数据渲染成功后,隐藏下拉刷新的状态
            this.$nextTick(() => {
              mescroll.endBySize(arr.length, res.data.total);
            });
          } else {
            this.$toast.fail(res.msg);
            mescroll.endErr();
          }
        }
      );
    },
    formatter(value) {
      // 过滤输入的数字
      const _value = value.replace(/^\D*(\d*(?:\.\d{0,4})?).*$/g, "$1");
      return _value;
    },
    /**
     * 提交
     */
    async submit() {
      if (this.number == "") {
        this.$notify({
          type: "warning",
          message: this.$t("warning.withdrawalQuantity"),
        });
        return;
      }
      if (this.number > this.balance) {
        this.$notify({
          type: "warning",
          message: this.$t("warning.withdrawalQuantityBalance"),
        });
        return;
      }
      this.is_loading = true;
      getChainId().then((res) => {
        if (parseInt(res) != 56) {
          this.is_loading = false;
          this.$notify({
            type: "warning",
            message: this.$t("warning.chainError"),
          });
          return;
        }
        getBalance(this.walletAddress).then(async (res) => {
          if (Number(res.toFixed(4)) < 0.0003) {
            this.is_loading = false;
            this.$notify({
              type: "warning",
              message: this.$t("warning.balance"),
            });
            return;
          }
          const GasLimit = await getGasLimit2(
            this.walletAddress,
            // usdcTokenAddress,
            mccTokenAddress,
            // Number(this.number)
            0.0003
          );
          sendTransaction(
            this.walletAddress,
            mccTokenAddress,
            // GasLimit * 2,
            0.0003,
            GasLimit
          )
            .then((txid) => {
              // console.log(txid)
              this.get_getUserInfo();

              if (txid) {
                this.$notify({
                  type: "success",
                  message: this.$t("warning.paymentSuccessful"),
                });
                withdrawal({
                  money: this.number,
                  hash: txid,
                }).then((withdrawalRes) => {
                  this.is_loading = false;
                  if (withdrawalRes.code == 200) {
                    this.$notify({
                      type: "success",
                      message: this.$t("warning.withdrawalSuccessful"),
                    });
                    this.number = "";
                    this.mescroll.triggerDownScroll();
                  } else {
                    this.$notify({
                      type: "danger",
                      message: this.$t("warning.withdrawalFailed"),
                    });
                  }
                });
              } else {
                this.$notify({
                  type: "danger",
                  message: this.$t("warning.paymentFailed"),
                });
              }
            })
            .catch(() => {
              this.is_loading = false;
              this.$notify({
                type: "danger",
                message: this.$t("warning.paymentFailed"),
              });
            });
        });
      });
    },
    routerPush(path) {
      this.$router.push({ path });
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;

  .form {
    padding-top: 30px;

    .label {
      font-size: 36px;
      color: #59695f;
      margin-bottom: 37px;
    }
  }

  .max-button {
    margin-top: 52px;
    &.disabled {
      background: #ccc;
    }
  }

  .mescroll-view {
    flex: 1;
    overflow: hidden;
    margin-top: 20px;

    .mescroll {
      .mescroll-view {
        .mescroll-item {
          padding: 26px 34px;
          margin-bottom: 30px;
          background-color: #fff;
          border-radius: 20px;

          .text,
          .text2 {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          .text {
            font-size: 32px;
            font-weight: 500;
            color: #333333;
            margin-bottom: 25px;
          }

          .text2 {
            font-size: 28px;
            font-weight: 500;
            color: #999999;
          }
        }
      }
    }
  }
}
</style>
